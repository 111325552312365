<template>
    <client-page>
        <sub-visual-intro />

        <section class="section">
            <v-container>
                <tab-intro />

                <section class="page-section">

                    <v-img src="/images/sub/company/org/org.svg" :aspect-ratio="1200 / 596" contain class="d-none d-md-block w-100" />
                    <v-img src="/images/sub/company/org/org-mo.svg" max-width="672" :alt="672 / 876" contain class="d-block d-md-none w-100 mx-auto" />

                    <v-sheet class="mt-10 mt-md-0">
                        <v-row justify="center" class="org">
                            <v-col cols="6" md="4" lg="2">
                                <div class="org__head">
                                    아동보호
                                </div>
                                <org-btn tit="서초꿈나래쉼터" />
                                <org-btn tit="용인늘품쉼터" />
                                <org-btn tit="광주아동보호전문기관" link="http://gj-child.kr/" />
                                <org-btn tit="파주아동보호전문기관" link="http://paju1391.kr/" />
                                <org-btn tit="광명아동보호전문기관" link="https://gmcchild.cafe24.com/" />
                            </v-col>
                            <v-col cols="6" md="4" lg="2">
                                <div class="org__head">
                                    중장년
                                </div>
                                <org-btn tit="성북50플러스센터" link="https://www.50plus.or.kr/sbc/index.do" />
                                <org-btn tit="강북50플러스센터" link="https://www.50plus.or.kr/gbc/index.do" />
                            </v-col>
                            <v-col cols="6" md="4" lg="2">
                                <div class="org__head">
                                    보육/아동돌봄
                                </div>
                                <org-btn tit="광진9호 키움센터" link="https://icare.seoul.go.kr/icare/user/careResve/BD_selectResveStleForm.do?q_fcltyId=GJ210601&q_tab=2" />
                                <org-btn tit="강남자곡키움센터" link="https://blog.naver.com/jagokkium" />
                                <org-btn tit="강동구 아이플러스 키움센터" link="https://blog.naver.com/kdiplus21" />
                                <org-btn tit="양지다함께돌봄센터" />
                                <org-btn tit="서초포레스타 2단지어린이집" link="https://www.youtube.com/@user-hj4gs3nq8h" />
                                <org-btn tit="마포대흥어린이집" link="https://cafe.naver.com/kid2" />
                            </v-col>
                            <v-col cols="6" md="4" lg="2">
                                <div class="org__head">
                                    노인
                                </div>
                                <org-btn tit="기흥노인복지관" link="http://www.ygsenior.or.kr/" />
                                <org-btn tit="영등포시니어클럽" link="https://ydpsc.or.kr/" />
                                <org-btn tit="성북시니어클럽" link="http://www.sbsc9988.or.kr/" />
                                <org-btn tit="광명시니어클럽" link="https://www.gmsjob.co.kr/" />
                            </v-col>
                            <v-col cols="6" md="4" lg="2">
                                <div class="org__head">
                                    지역복지
                                </div>
                                <org-btn tit="동탄어울림종합복지관" link="http://www.dongtanwith.or.kr/" />
                                <org-btn tit="강남지역자활센터" link="https://www.gangnam.go.kr/office/gjhope/main.do" />
                            </v-col>
                            <v-col cols="6" md="4" lg="2">
                                <div class="org__head">
                                    장애인
                                </div>
                                <org-btn tit="동탄어울림장애인 주간보호센터" />
                            </v-col>
                        </v-row>
                    </v-sheet>
                </section>

                <section class="page-section">
                    <h4 class="tit tit--sm line-height-1 mb-20 mb-lg-40">
                        임원현황
                    </h4>
                    <table class="board-list">
                        <thead class="d-none d-lg-table-header">
                            <tr>
                                <th style="width:25%;" class="board-list__txt">이름</th>
                                <th style="width:25%;" class="board-list__txt">직위</th>
                                <th style="width:50%;" class="board-list__txt">소속</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    전**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    대표이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    사회복지법인 위드캔복지재단 이사장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    이**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    상임이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    사무총장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    공**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    장애인 복지관장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    손**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    국공립어린이집 원장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    오**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    복지관장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    원**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    노인복지센터장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    이**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    장애인 권익위원
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    최**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    이사
                                </td>
                                <td align="center" class="board-list__txt">
                                    종합사회복지관 관장
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    최**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    감사
                                </td>
                                <td align="center" class="board-list__txt">
                                    회계사
                                </td>
                            </tr>
                            <tr>
                                <td align="center" class="board-list__tit">
                                    이**
                                </td>
                                <td align="center" class="board-list__txt board-list__txt--first">
                                    감사
                                </td>
                                <td align="center" class="board-list__txt">
                                    복지관장
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </section>
            </v-container>
        </section>
    </client-page>
</template>

<script>
import ClientPage from "@/pages/client/templates/ClientPage.vue";
import SubVisualIntro from "@/components/client/sub/sub-visual-intro.vue";
import TabIntro from "@/components/client/sub/tab-intro.vue";
import OrgBtn from "@/components/client/sub/org-btn.vue";
export default {
    components: {
        ClientPage,
        SubVisualIntro,
        TabIntro,
        OrgBtn,
    },
};
</script>

<style lang="scss" scoped>

.org{
    &__head{
        width: 100%;
        height: 42px;
        background-color: #666;
        font-size: 1.6rem;
        font-weight: 700;
        padding: 10px;
        margin-bottom: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        color: white;
        position: relative;
        &::before{            
            content: "";
            display: none;
            position: absolute;
            width: 24px;
            height: 1px;
            background-color: #ddd;
            top: 50%;
            right: 0;
            transform: translate(100%, -50%);
        }
    }
}
@media (min-width:576px){
}
@media (min-width:768px){
}
@media (min-width:1024px){
    .org{
        &__head{
            height: 48px;
            font-size: 1.8rem;
            margin-bottom: 24px;
            &::before{
                display: block;
            }
        }
        >[class*=col]:last-child{
            .org__head::before{
                display: none;
            }
        }
    }
}
@media (min-width:1200px){
}


.board-list {
    position: relative;
    border-top: 0;
}

.board-list::before,
.board-list::after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 1px;
    left: 0;
    background-color: #111;
}
.board-list::before {
    top: 0;
}
.board-list::after {
    bottom: 0;
}

.board-list tbody tr:last-child {
    border-bottom: 0;
}

.board-list thead tr {
    background-color: #f5f5f5;
}
.board-list tbody tr {
    cursor: auto;
}
.board-list tbody tr td:last-child {
    text-align: left;
}
@media (min-width: 576px) {
}
@media (min-width: 768px) {
}
@media (min-width: 1024px) {
    .board-list tr th {
        color: #111;
        font-weight: 500;
        font-size: 1.7rem;
    }
    .board-list tr th,
    .board-list tr td {
        height: 50px;
        padding: 10px 24px;
    }
    .board-list tr *:not(:last-child) {
        border-right: 1px solid var(--border-color);
    }
    .board-list__tit {
        text-align: center;
    }
}
@media (min-width: 1200px) {
}
</style>

var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('client-page', [_c('sub-visual-intro'), _c('section', {
    staticClass: "section"
  }, [_c('v-container', [_c('tab-intro'), _c('section', {
    staticClass: "page-section"
  }, [_c('v-img', {
    staticClass: "d-none d-md-block w-100",
    attrs: {
      "src": "/images/sub/company/org/org.svg",
      "aspect-ratio": 1200 / 596,
      "contain": ""
    }
  }), _c('v-img', {
    staticClass: "d-block d-md-none w-100 mx-auto",
    attrs: {
      "src": "/images/sub/company/org/org-mo.svg",
      "max-width": "672",
      "alt": 672 / 876,
      "contain": ""
    }
  }), _c('v-sheet', {
    staticClass: "mt-10 mt-md-0"
  }, [_c('v-row', {
    staticClass: "org",
    attrs: {
      "justify": "center"
    }
  }, [_c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "org__head"
  }, [_vm._v(" 아동보호 ")]), _c('org-btn', {
    attrs: {
      "tit": "서초꿈나래쉼터"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "용인늘품쉼터"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "광주아동보호전문기관",
      "link": "http://gj-child.kr/"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "파주아동보호전문기관",
      "link": "http://paju1391.kr/"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "광명아동보호전문기관",
      "link": "https://gmcchild.cafe24.com/"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "org__head"
  }, [_vm._v(" 중장년 ")]), _c('org-btn', {
    attrs: {
      "tit": "성북50플러스센터",
      "link": "https://www.50plus.or.kr/sbc/index.do"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "강북50플러스센터",
      "link": "https://www.50plus.or.kr/gbc/index.do"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "org__head"
  }, [_vm._v(" 보육/아동돌봄 ")]), _c('org-btn', {
    attrs: {
      "tit": "광진9호 키움센터",
      "link": "https://icare.seoul.go.kr/icare/user/careResve/BD_selectResveStleForm.do?q_fcltyId=GJ210601&q_tab=2"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "강남자곡키움센터",
      "link": "https://blog.naver.com/jagokkium"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "강동구 아이플러스 키움센터",
      "link": "https://blog.naver.com/kdiplus21"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "양지다함께돌봄센터"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "서초포레스타 2단지어린이집",
      "link": "https://www.youtube.com/@user-hj4gs3nq8h"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "마포대흥어린이집",
      "link": "https://cafe.naver.com/kid2"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "org__head"
  }, [_vm._v(" 노인 ")]), _c('org-btn', {
    attrs: {
      "tit": "기흥노인복지관",
      "link": "http://www.ygsenior.or.kr/"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "영등포시니어클럽",
      "link": "https://ydpsc.or.kr/"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "성북시니어클럽",
      "link": "http://www.sbsc9988.or.kr/"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "광명시니어클럽",
      "link": "https://www.gmsjob.co.kr/"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "org__head"
  }, [_vm._v(" 지역복지 ")]), _c('org-btn', {
    attrs: {
      "tit": "동탄어울림종합복지관",
      "link": "http://www.dongtanwith.or.kr/"
    }
  }), _c('org-btn', {
    attrs: {
      "tit": "강남지역자활센터",
      "link": "https://www.gangnam.go.kr/office/gjhope/main.do"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "6",
      "md": "4",
      "lg": "2"
    }
  }, [_c('div', {
    staticClass: "org__head"
  }, [_vm._v(" 장애인 ")]), _c('org-btn', {
    attrs: {
      "tit": "동탄어울림장애인 주간보호센터"
    }
  })], 1)], 1)], 1)], 1), _c('section', {
    staticClass: "page-section"
  }, [_c('h4', {
    staticClass: "tit tit--sm line-height-1 mb-20 mb-lg-40"
  }, [_vm._v(" 임원현황 ")]), _c('table', {
    staticClass: "board-list"
  }, [_c('thead', {
    staticClass: "d-none d-lg-table-header"
  }, [_c('tr', [_c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "25%"
    }
  }, [_vm._v("이름")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "25%"
    }
  }, [_vm._v("직위")]), _c('th', {
    staticClass: "board-list__txt",
    staticStyle: {
      "width": "50%"
    }
  }, [_vm._v("소속")])])]), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 전** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 대표이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 사회복지법인 위드캔복지재단 이사장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 상임이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 사무총장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 공** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 장애인 복지관장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 손** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 국공립어린이집 원장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 오** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 복지관장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 원** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 노인복지센터장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 장애인 권익위원 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 최** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 종합사회복지관 관장 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 최** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 감사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 회계사 ")])]), _c('tr', [_c('td', {
    staticClass: "board-list__tit",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 이** ")]), _c('td', {
    staticClass: "board-list__txt board-list__txt--first",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 감사 ")]), _c('td', {
    staticClass: "board-list__txt",
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" 복지관장 ")])])])])])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }